//  药师在线主页
<template>
  <div>
    <Header :userlist="userlist" @changeState="changeState" />
    <div class="wrapper">
      <div class="bg1">
        <div class="wrap">
          <Mains v-if="state === 0" :userlist="userlist" @changeState="changeState" />
          <UserSet v-if="state === 1" :navTab="navTab" @changeState="changeState" @change="change" />
	<!-- 	  <SetTime v-if="state===8" @changeState="changeState" :type="1"></SetTime> -->
          <Wallet v-if="state === 2" />
          <Service v-if="state === 3" />
          <Online v-if="state === 4" title="药师在线" timeType="药师工作时间" @changeState="changeState" />
          <AuditRecord v-if="state === 5" :recordList="recordList" @changeState="changeState" />
          <SysMessage v-if="state === 6" />
          <RecordInfo v-if="state === 7" :pCode="pCode" :type="3" @changeState="changeState" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
	import SetTime from "@/components/personal_repect/SetTime"; // 个人资料
import Header from "./header";
import Footer from "@/components/public/public_bottom";
import Mains from "./main"; //主页
import UserSet from "@/components/personal_repect/userData"; // 修改个人信息
import Wallet from "@/components/personal_repect/wallet/wallet"; // 我的钱包
import Service from "@/components/personal_repect/service"; // 联系客服
import Online from "@/components/personal_repect/online"; // 调配在线
import SysMessage from "@/components/personal_repect/sysMessage";
import AuditRecord from "./auditRecord"; // 审核记录
import RecordInfo from "./recordInfo";
import { usergetinfo } from "@/request/user"; // 获取用户信息

export default {
  components: {
    Header,
    Footer,
    Mains,
    Online,
    UserSet,
    AuditRecord,
    Wallet,
    Service,
    SysMessage,
    RecordInfo,
	SetTime
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.query.msg) {
        vm.state = 6;
      }
    });
  },
  data() {
    return {
      state: 0,
      navTab: 0,
      userlist: {}, // 用户信息
      recordList: [],
      pCode: ""
    };
  },
  created() {
    if (this.$route.query.state) {
      this.state = parseInt(this.$route.query.state);
    }
    if (this.$token) {
      this.start();
    }
  },
  methods: {
    start() {
      usergetinfo({ token: this.$token }).then(res => {
        this.userlist = res.data;
      });
    },
    changeState(num, pCode) {
      this.state = num;
      if (num === 2 && this.state === 2) {
        this.state = 100;
        this.$nextTick(() => {
          this.state = 2;
        });
      }
      if (num == 7) {
        this.pCode = pCode;
      }
    },
    change(num) {
      this.navTab = num;
    }
  }
};
</script>

<style scoped>
.wrap {
  width: 1200px;
  margin: 0 auto;
}
.wrapper {
  background: #f5f5f5;
  padding: 40px 0px;
}
</style>