// 记录详情
<template>
	<div class="recordInfo">
		<!-- 非图片处方单 -->
		<template v-if="preInfo && preInfo.type == 0">
			<div class="content">
				<div class="bill_wrap">
					<div class="bill">
						<div class="date">{{ preInfo.add_time }}</div>
						<div class="invald">本处方单3个月内有效</div>
						<h3>处方笺</h3>
						<el-row>
							<el-col :span="12">
								科别:
								<span>{{ preInfo.inquiry_info.category_name }}</span>
							</el-col>
							<el-col :span="12">
								处方号:
								<span>{{ preInfo.p_code }}</span>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="8">
								姓名:
								<span>{{ preInfo.inquiry_info.realname }}</span>
							</el-col>
							<el-col :span="8">
								年龄:
								<span>{{ preInfo.inquiry_info.age }}</span>
							</el-col>
							<el-col :span="8">
								性别:
								<span>{{ preInfo.inquiry_info.sex === 0 ? "保密" : (preInfo.inquiry_info.sex == 1 ? "男" : "女")}}</span>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								过敏史:
								<span>{{ preInfo.inquiry_info.allergy }}</span>
							</el-col>
							<el-col :span="12">
								遗传史:
								<span>{{ preInfo.inquiry_info.genetic_disease}}</span>
							</el-col>
						</el-row>
						<el-row>
							临床诊断:
							<span>{{ preInfo.inquiry_info.diagnosis }}</span>
						</el-row>
						<div class="content">
							<div class="type">R</div>
							<div class="name" v-for="(item, i) in preInfo.goods_list" :key="i">
								<el-row>
									<el-col :span="8">{{ item.goods_name }}</el-col>
									<el-col :span="8">{{item.spec}}</el-col>
									<el-col :span="8">x{{item.num}} {{item.unit}}</el-col>
									<el-col :span="8">用法</el-col>
									<el-col :span="8">{{ item.drugRoute_name}}</el-col>
									<el-col :span="8">每次{{item.drugDosage}}{{item.drugUseUnit_name}}</el-col>
								</el-row>
							</div>
						</div>

						<div class="graph">
							<el-row>
								<el-col :span="12">
									<p>医生签字:</p>
									<img :src="doctorSign.audit_sign" alt />
								</el-col>
								<el-col :span="12">
									<p>药师签字:</p>
									<img :src="pharmSign.audit_sign" alt />
								</el-col>
							</el-row>
						</div>
						
					</div>
					<div style="margin-left: 250px; font-size: 20px;" v-if="preInfo.status==3">拒绝原因:{{preInfo.sign_list[1].note}}</div>
				</div>
				
			</div>
		</template>
		<!-- 图片处方单 -->
		<template v-else-if="preInfo && preInfo.type == 1  ||preInfo.type==2">
			<div class="wrapper">
				<div class="pre_img">
					<img :src="preInfo.pic" alt />
				</div>
				<div class="drug_wrap">
					<div class="title">
						<span class="pre_title">审核建议</span>
						<hr />
						<p class="proposal">{{preInfo.sign_list[0].audit_opinion}}</p>
					</div>
					<div class="title" v-if="preInfo.goods_list.length>0">
						<span class="pre_title">药师选药</span>
						<hr />
					</div>
					<div class="drugInfo"  v-if="preInfo.goods_list.length>0">
						<div>
							<span>药品信息</span>
						</div>
						<div>
							<span>数量</span>
						</div>
					</div>
					<div class="content" v-for="(good, i) in preInfo.goods_list" :key="i">
						<div class="info">
							<div class="title">
								<div class="shop">
									<img :src="good.goods_img" alt />
									<span>{{ good.goods_name }}</span>
								</div>
								<div class="num">
									{{good.num}}
								</div>
							</div>
							<div class="inputlist">
								<div class="inputbox">
									<label>医嘱类型 :</label>
									<div>
										<el-select v-model="good.orderCode" :disabled="true" placeholder="请选择" @change="getCode(good.orderCode,exhortlist,i,'orderClassification')">
		
										</el-select>
									</div>
								</div>
								<div class="inputbox">
									<label>单次计量 :</label>
									<div>
										<el-input  :disabled="true" v-model="good.drugDosage" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div class="inputbox">
									<label>计量单位 :</label>
									<div>
										<el-select v-model="good.drugUseUnit_name"  :disabled="true" placeholder="请选择" @change="getCode(good.drugUseUnit_name,unitlist,i,'drugUseUnit')">
				
										</el-select>
									</div>
								</div>
								<div class="inputbox">
									<label>用药途径 :</label>
									<div>
										<el-select v-model="good.drugRoute_name"  :disabled="true" placeholder="请选择" @change="getCode(good.drugRoute_name,channellist,i,'drugRoute')">
								
										</el-select>
									</div>
								</div>
								<div class="inputbox">
									<label>用药频率 :</label>
									<div>
										<el-select  :disabled="true" v-model="good.drugFrequency_name" placeholder="请选择" @change="getCode(good.drugFrequency_name,ratelist,i,'drugFrequency')">
								
										</el-select>
									</div>
								</div>
								<div class="inputbox">
									<label>总剂量 :</label>
									<div>
										<el-input  :disabled="true" v-model="good.drugIntegraldose" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div class="inputbox">
									<label>用药天数 :</label>
									<div>
										<el-input  :disabled="true" v-model="good.drugDay" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div class="inputbox">
									<label>是否皮试 :</label>
									<div>
										 <el-input :disabled="true" type="text" v-model="good.sink"></el-input>
									</div>
								</div>
							</div>
						</div>

					</div>
					<div class="reason" v-if="preInfo.status==3">
						拒绝原因:{{preInfo.sign_list[0].note}}
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	// 获取处方单详情
	import {
		getInfo
	} from "@/request/personnel";

	export default {
		props: ["pCode", "type"],
		data() {
			return {
				preInfo: "",
				doctorSign: {}, // 医生签字对象
				pharmSign: {}, // 药师签字对象
				deploySign: {}, // 调配签字对象
				checkSign: {} ,// 复核签字对象
				skinlsit: [{
					code: "0",
					name: "不需要皮试"
				}, {
					code: "1",
					name: "需要皮试"
				}], //是否皮试
			};
		},
		created() {
			getInfo({
				token: this.$token,
				p_code: this.pCode
			}).then(res => {
				this.preInfo = res.data;
				console.log(this.preInfo);
				for(let item of this.preInfo.goods_list){
					if(item.skinTest==0){
						item.sink="不需要皮试"
					}else{
						item.sink="需要皮试"
					}
				}
				this.preInfo.sign_list.forEach(item => {
					if (item.cert_type == 1 || item.cert_type == 2) {
						this.doctorSign = item;
					} else if (item.cert_type == 3) {
						this.pharmSign = item;
					} else if (item.cert_type == 4) {
						this.deploySign = item;
					} else if (item.cert_type == 5) {
						this.checkSign = item;
					}
				});
				console.log(this.preInfo);
			});
		}
	};
</script>

<style lang="less" scoped>
	.proposal{
		margin-left: 30px;
		margin-top: 20px;
	}
	.reason{
		padding: 10px;
		margin-bottom: 20px;
		margin-left: 20px;
	}
	.recordInfo {
		width: 1200px;
		min-height: 1169px;
		background: #fff;
		overflow: hidden;
		
	}

	.bill {
		box-sizing: border-box;
		box-shadow: 5px 5px 5px #ccc;
		width: 702px;
		min-height: 922px;
		margin-left: 249px;
		margin-top: 66px;
		margin-bottom: 66px;
		background: #f5f5f5;
		font-size: 16px;
		border: 1px solid #ccc;
		padding: 20px;

		.date {
			margin-bottom: 5px;
			font-size: 24px;
			color: #010101;
		}

		.invald {
			font-size: 24px;
			color: #010101;
		}

		h3 {
			font-size: 30px;
			font-weight: 500;
			color: #010101;
			text-align: center;
			margin-bottom: 15px;
		}

		>.el-row {
			line-height: 50px;
			font-size: 24px;
			color: #000000;
			font-weight: 500;

			span {
				font-weight: 400;

				font-size: 22px;
				color: #616161;
			}
		}

		.content {
			min-height: 200px;
			overflow: hidden;
			border-bottom: 1px solid #424242;

			.el-row {
				border: none;
				font-size: 22px;
				color: #616161;
			}

			.el-row {
				.el-col {
					margin: 3px 0;
				}

				.el-col:nth-child(2) {
					text-align: center;
				}

				.el-col:nth-child(4) {
					text-indent: 2em;
				}

				.el-col:nth-child(5) {
					text-align: center;
				}

				.el-col:nth-child(3) {
					text-align: right;
				}

				.el-col:nth-child(6) {
					text-align: right;
				}
			}

			.type {
				font-size: 22px;
				font-weight: 700;
			}

			.name {
				margin: 3px 0;
			}
		}

		.graph {
			font-size: 24px;
			margin-top: 40px;

			.el-row {
				margin-top: 18px;

				img {
					width: 320px;
					min-height: 200px;
				}
			}
		}
	}

	// 图片处方单样式
	hr {
		border: none;
		width: 960px;
		height: 2px;
		display: inline-block;
		margin-left: 10px;
		margin-bottom: 5px;
		background-color: #f5f5f5 !important;
	}

	.recordInfo {
		.wrapper {
			background: #ffffff;
			font-size: 18px;
		}

		.pre_title {
			margin-left: 30px;
		}

		.pre_img {
			margin-top: 42px;
			height: 500px;
			background: #fff;
			overflow: hidden;

			img {
				width: 500px;
				height: 400px;
				display: block;
				margin: 0 auto;
			}
		}

		.drug_wrap .title {
			margin-bottom: 25px;
		}

		.drug_wrap .drugInfo {
			width: 1200px;
			display: flex;
			height: 65px;
			background: #fbfbfb;
			line-height: 65px;
			margin-bottom: 16px;

			div:nth-of-type(1) {
				width: 900px;

				span {
					margin-left: 65px;
				}
			}

			div:nth-of-type(2) {
				width: 200px;

				span {
					margin-left: 100px;
				}
			}
		}

		.drug_wrap .content {
			background: #fbfbfb;
			margin-bottom: 16px;

			.title {
				display: flex;
				justify-content: space-between;
				padding: 20px 80px;
				line-height: 40px;

				.shop {
					line-height: 70px;
				}

				img {
					width: 50px;
					height: 50px;
					display: inline-block;
					vertical-align: middle;
					margin-right: 10px;
				}

				.num {
					margin-right: 95px;
				}
			}

			.inputlist {
				display: flex;
				flex-wrap: wrap;

				.inputbox {
					width: 23%;
					display: flex;
					margin: 10px;

					label {
						width: 60%;
						margin-top: 3px;
					}
				}
			}

		}
	}
</style>
